import React from 'react';

import carrouselSrc from 'assets/illustrations/carrousel.png';
import sellServiceImg from 'assets/illustrations/sell-services.png';
import buyServiceImg from 'assets/illustrations/buy-services.png';

import { Column, Grid } from 'components/grid/grid.component';
import { LinkBubble } from 'components/grid/link-bubble';
import { WhiteBackground } from 'layouts/backgrounds/white-background/white-background.component';
import { Main } from 'layouts/main/main.component';
import { PageHeader } from 'layouts/page-header/page-header.component';
import { SearchHeader } from './components/SearchHeader/search-header.component';
import ServiceFlow from './components/serviceFlow/service-Flow.component';
import SvipparQuestions from './components/SvipparQuestions/svipparQuestions.component';
import { useModalsStore, useUserStore } from 'stores';
import { AdType } from 'constants/ad-type.enum';
import { useAdsStore } from 'services/ads.service';
import Informational from './components/Informational/Informational.component';

import style from './landing-page.module.scss';
import CreateAccountCTA from './components/CreateAccountCTA/CreateAccountCTA.component';
// import SvippoIntroFeed from './components/SvippoIntroFeed/SvippoIntroFeed.component';

export const LandingPageView: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const setShowRegistration = useModalsStore((state) => state.setShowRegistration);
  const [clearFilter, setFilter] = useAdsStore((state) => [state.clearFilter, state.setFilter]);

  return (
    <Main backgroundComp={WhiteBackground}>
      <PageHeader>
        <Grid className={style.header}>
          <Column span={[6, 6, 12, 4]}>
            <SearchHeader />
          </Column>
          <Column span={[5, 5, 8, 4]} start={[8, 8, 3, 1]}>
            <div className={style.carrousel}>
              <img src={carrouselSrc} alt="Illustration av flera annonser som erbjuder olika typer av tjänster" />
            </div>
          </Column>
        </Grid>
      </PageHeader>

      <Grid id="bubbleRow" className={style.bubbleRow}>
        <Column span={[3, 3, 4, 3]} start={[3, 3, 2, 1]} id={style.bubbleRow}>
          {!user ? (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill skapa annonser"
              linkText="Skapa ett Svippokonto"
              linkUrl="/"
              onClick={() => setShowRegistration(true)}
              imageUrl={sellServiceImg}
            />
          ) : user.svipper ? (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill sälja mina tjänster"
              linkText="Skapa en annons"
              linkUrl="/create-ad"
              state={{ type: AdType.Service }}
              imageUrl={sellServiceImg}
            />
          ) : (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill sälja mina tjänster"
              linkText="Bli en svippare direkt"
              linkUrl="/become-svippare"
              imageUrl={sellServiceImg}
            />
          )}
        </Column>
        <Column span={[3, 3, 4, 3]} start={[8, 8, 8, 1]}>
          <LinkBubble
            onClick={() => {
              clearFilter();
              setFilter({ type: AdType.Service });
            }}
            className={style.buyServices}
            title="Jag vill ha hjälp med något"
            linkText="Upptäck svippade tjänster"
            linkUrl="/search"
            imageUrl={buyServiceImg}
          />
        </Column>
      </Grid>
      <div className={style.pagestyle}>
        <ServiceFlow />
        <Informational />
      </div>
      {/* <SvippoIntroFeed /> TODO: fix backend for this!!! */}
      <SvipparQuestions />
      <CreateAccountCTA />
    </Main>
  );
};
