import style from './service-Flow.module.scss';
import getInContact from 'assets/illustrations/comeInContact.png';
import kommisionComplete from 'assets/illustrations/commissionComplete.png';
import searchAndFind from 'assets/illustrations/searchAndFind.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import { Link } from 'react-router-dom';

const ServiceFlow = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const processItems = [
    {
      img: searchAndFind,
      title: 'Utforska & Hitta',
      description:
        'Bläddra igenom tjänster och välj det som passar ditt behov bäst. Inget krångel, bara ren och skär upptäckarglädje.',
      linkText: 'Utforska',
      linkTo: '/search',
    },
    {
      img: getInContact,
      title: 'Kom i kontakt',
      description:
        'När du hittar rätt tjänst, ta kontakt med utföraren. Diskutera vad du behöver hjälp med, utan mellanhänder.',
      linkText: 'Läs mer',
      linkTo: '/search',
    },
    {
      img: kommisionComplete,
      title: 'Uppdraget utförs',
      description:
        'Kom överens om upplägget och låt utföraren ta hand om resten. Vid utfört uppdrag fakturerar svipparen.',
      linkText: 'Kom igång',
      linkTo: '/search',
    },
  ];

  return (
    <div className={style.serviceFlowContainer}>
      <div className={style.title}>
        <h2>Så här fungerar Svippo</h2>
      </div>

      {isMobile ? (
        <Glider className={style.mobileSlider} draggable scrollLock hasDots={false} slidesToShow={1} slidesToScroll={1}>
          {processItems.map((item, index) => (
            <div key={index} className={style.processItem}>
              <img src={item.img} alt={item.title} />
              <div className={style.textContainer}>
                <span className={style.itemTitle}>{item.title}</span>
                <p>{item.description}</p>
                <Link to={item.linkTo}>{item.linkText}</Link>
              </div>
            </div>
          ))}
        </Glider>
      ) : (
        <div className={style.processRow}>
          {processItems.map((item, index) => (
            <div key={index} className={style.processItem}>
              <img src={item.img} alt={item.title} />
              <div className={style.textContainer}>
                <span className={style.itemTitle}>{item.title}</span>
                <p>{item.description}</p>
                <Link to={item.linkTo}>{item.linkText}</Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceFlow;
