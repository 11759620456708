import style from './svipparQuestions.module.scss';
import Faq from 'react-faq-component';

const data = {
  rows: [
    {
      title: 'Vad är Svippo?',
      content:
        'Svippo är en innovativ digital plattform som gör det möjligt för användare att både erbjuda och efterfråga tjänster.',
    },
    {
      title: 'Kostar det att använda Svippo?',
      content:
        `
          Nej.
          Att skapa ett konto och publicera inlägg på Svippo är gratis.
          Det finns ingen avgift för användare som letar efter tjänster samt tar inte Svippo en transaktionsavgift.
        `,
    },
    {
      title: 'Hur tar jag betalt för en tjänst?',
      content:
        `
          Som privatperson behöver du skicka en faktura till kunden genom en ”fakturera utan företags” tjänst.
          Som företag skickar du en faktura som vanligt.
        `,
    },
    {
      title: 'Hur bokar jag en tjänst?',
      content:
        `
          Kontakta Svipparen ”utföraren” av uppdraget.
          Kom överens om uppgiften och diskutera pris.
          Svippo verkar endast som marknadsplatsen och hanterar inte tvister mellan partnerna.
          <br>
          Läs mer <a href="https://info.svippo.se/2023/07/10/bokningsprocessen/" target="_blank">här</a>.
        `,
    },
  ],
};

const config = {
  openOnload: [1],
};

const styles = {
  bgColor: 'transparent',
};

const SvipparQuestions = () => {
  return (
    <div className={style.serviceFlowContainer}>
      <div className={style.gridContainer}>
        <div className={style.leftColumn}>
          <h3 className={style.title}>Vi förstår om du har frågor över Svippo</h3>
        </div>
        <div className={style.rightColumn}>
          <Faq data={data} config={config} styles={styles} />
        </div>
      </div>
    </div>
  );
};

export default SvipparQuestions;
