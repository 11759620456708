import React from 'react';
import style from './Informational.module.scss';
import Guide from 'assets/Images/Guide.png';
import Tjänst from 'assets/Images/tjänst.png';
import FaqButton from './Components/FaqButton.component';

const Informational = () => {
  const svippareQuestions = [
    { text: 'Hur tar jag betalt?', link: 'https://info.svippo.se/2023/07/15/ta-betalt/' },
    {
      text: 'Är Svippo kostnadsfritt?',
      link: 'https://info.svippo.se/2023/07/26/vad-ar-fordelarna-med-att-anvanda-svippo-jamfort-med-andra-liknande-plattformar/',
    },
    { text: 'Hur skapar jag en profil?', link: 'https://info.svippo.se/2023/07/15/registrera-som-svippare/' },
  ];

  const beställareQuestions = [
    { text: 'Hur bokar jag en tjänst?', link: 'https://info.svippo.se/2023/07/10/bokningsprocessen/' },
    { text: 'Hur betalar jag för en tjänst?', link: 'https://info.svippo.se/2023/07/14/betalningsalternativ/' },
    { text: 'Kan jag avboka en bokad tjänst?', link: 'https://info.svippo.se/2023/07/14/avbokningsregler/' },
  ];

  return (
    <div className={style.informationalContainer}>
      <div className={style.row}>
        <div className={style.column}>
          <div className={style.imageContainer}>
            <img src={Guide} alt="Guide visual" className={style.image} />
          </div>
        </div>
        <div className={style.column}>
          <div className={style.textContainer}>
            <span className={style.tag}>Att vara en Svippare</span>
            <h2 className={style.title}>Din guide för att börja utföra jobb på Svippo</h2>
            <p className={style.description}>
              Ta det första steget mot att bli en eftertraktad Svippare – börja med en profil som sticker ut.
            </p>
            <FaqButton questions={svippareQuestions} />
          </div>
        </div>
      </div>

      <div className={style.row} id={style.Customer}>
        <div className={style.column}>
          <div className={style.imageContainer}>
            <img src={Tjänst} alt="Service visual" className={style.image} />
          </div>
        </div>
        <div className={style.column}>
          <div className={style.textContainer}>
            <span className={style.tagBestallare}>Att vara en beställare</span>
            <h2 className={style.title}>Hitta rätt tjänst för dina behov på Svippo</h2>
            <p className={style.description}>
              Upptäck hur enkelt det är att förvandla dina projekt eller uppgifter till verklighet med hjälp av en
              Svippare.
            </p>
            <FaqButton questions={beställareQuestions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informational;
