import React from 'react';
import styles from './FaqButton.module.scss';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface FaqButtonProps {
  questions: { text: string; link: string }[];
}

const FaqButton: React.FC<FaqButtonProps> = ({ questions }) => {
  return (
    <div className={styles.faqList}>
      {questions.map((question, index) => (
        <Link key={index} to={question.link} target="_blank" className={styles.faqButton}>
          <span>{question.text}</span>
          <ArrowForwardIcon />
        </Link>
      ))}
    </div>
  );
};

export default FaqButton;
