import React, { useRef, useEffect, useState } from 'react';
import { HTMLProps } from 'react';
import classNames from 'classnames';
import style from './link-bubble.module.scss';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Link } from 'react-router-dom';

interface LinkBubbleProps extends HTMLProps<HTMLDivElement> {
  imageUrl: string;
  title: string;
  linkText: string;
  linkUrl: string;
  onClick?: () => void;
  state?: Record<string, any>;
}

const LinkBubble = (props: LinkBubbleProps) => {
  const { className, imageUrl, title, linkText, linkUrl, onClick, state } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [arrowBackgroundColor, setArrowBackgroundColor] = useState<string | null>(null);

  useEffect(() => {
    if (linkRef.current) {
      const computedColor = getComputedStyle(linkRef.current).color;
      const colorMatch = computedColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (colorMatch) {
        // eslint-disable-next-line
        const [_, r, g, b] = colorMatch;
        setArrowBackgroundColor(`rgba(${r}, ${g}, ${b}, 0.15)`);
      }
    }
  }, []);

  return (
    <div className={classNames(style.container, className)}>
      <img src={imageUrl} alt="" />
      <span>{title}</span>
      <Link
        ref={linkRef}
        to={linkUrl}
        onClick={() => {
          onClick && onClick();
        }}
        state={state}
      >
        {linkText}
        <NavigateNextOutlinedIcon
          className={classNames(style.arrow, className)}
          fontSize="large"
          style={{ backgroundColor: arrowBackgroundColor || 'transparent' }}
        />
      </Link>
    </div>
  );
};

export { LinkBubble };
